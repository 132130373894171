.report-table {
  width: 120%;
  border-spacing: 0 10px; }
  .report-table th {
    padding-left: 14px; }
  .report-table .data-row {
    background-color: white; }
    .report-table .data-row .left-border {
      border-top-left-radius: 18px;
      border-bottom-left-radius: 18px; }
    .report-table .data-row .right-border {
      border-top-right-radius: 18px;
      border-bottom-right-radius: 18px; }
    .report-table .data-row td {
      padding: 18px 0 18px 14px; }

.menu-container {
  height: 100%;
  width: 100%;
  padding: 0 2% 2% 2%; }
  .menu-container * {
    font-family: 'Poppins' !important; }
  .menu-container h2 {
    color: #414042;
    font-size: 2.3em; }
  .menu-container h3 {
    color: #009444;
    font-size: 1.8em;
    font-weight: 500;
    margin-bottom: 0.5%; }
  .menu-container h3 + p {
    color: #009444;
    font-weight: 500;
    margin-bottom: 2.5%; }
  .menu-container p {
    font-weight: 300; }
  .menu-container .title {
    height: 10%;
    width: inherit;
    display: flex;
    align-items: center; }
    .menu-container .title h1 {
      font-size: 2.5em; }
  .menu-container .grid-parent {
    height: 90%;
    width: inherit;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px; }
    .menu-container .grid-parent > div {
      display: flex;
      position: relative;
      flex-direction: column;
      justify-content: space-between;
      padding: 4% 25% 4% 5%;
      background-color: white;
      border-radius: 1em; }
      .menu-container .grid-parent > div .action-btn {
        position: absolute;
        right: 5%;
        bottom: 10%; }
      .menu-container .grid-parent > div button {
        border: none; }
      .menu-container .grid-parent > div button:hover {
        cursor: pointer; }
    .menu-container .grid-parent div:first-child {
      position: relative;
      grid-area: 1 / 1 / 2 / 3;
      justify-content: center;
      padding: 5.5% 40% 5.5% 3% !important;
      background-color: #e1f0e1;
      overflow: hidden; }
      .menu-container .grid-parent div:first-child p {
        font-size: 1.1em; }
      .menu-container .grid-parent div:first-child img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 1%;
        height: 350px; }
    .menu-container .grid-parent div:nth-child(2) {
      grid-area: 2 / 1 / 3 / 2; }
    .menu-container .grid-parent div:nth-child(3) {
      grid-area: 2 / 2 / 3 / 3; }
    .menu-container .grid-parent div:last-child {
      grid-area: 3 / 1 / 4 / 2; }

.report-container {
  height: 100%;
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 20% 25% 55%;
  padding: 5% 3% 2% 3%; }
  .report-container * {
    font-family: 'Poppins' !important; }
  .report-container div:first-child {
    position: absolute;
    top: 3%;
    left: 3%; }
    .report-container div:first-child button {
      border: none;
      padding-top: 0; }
    .report-container div:first-child button:hover {
      cursor: pointer; }
    .report-container div:first-child p {
      font-size: 1.1em;
      margin-left: 10px; }
  .report-container div:nth-child(2) {
    grid-area: 1 / 1 / 2 / 5; }
    .report-container div:nth-child(2) img {
      height: 50px;
      width: 50px; }

.analysis-container {
  height: 100%;
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 20% 30% 50%;
  padding: 5% 3% 2% 3%; }
  .analysis-container * {
    font-family: 'Poppins' !important; }
  .analysis-container h2 {
    font-size: 2.5em;
    font-weight: 600; }
  .analysis-container h3 {
    font-weight: 500; }
  .analysis-container > div:first-child {
    position: absolute;
    top: 3%;
    left: 3%; }
    .analysis-container > div:first-child button {
      border: none;
      padding-top: 0; }
    .analysis-container > div:first-child button:hover {
      cursor: pointer; }
    .analysis-container > div:first-child p {
      font-size: 1.1em;
      margin-left: 10px; }
  .analysis-container > div:nth-child(2) {
    grid-area: 1 / 1 / 2 / 2;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .analysis-container > div:nth-child(2) div h2, .analysis-container > div:nth-child(2) div h3 {
      margin: 0; }
    .analysis-container > div:nth-child(2) img {
      height: 50px;
      width: 50px; }
  .analysis-container > div:nth-child(3) {
    grid-area: 2 / 1 / 3 / 2;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 80% 20%; }
    .analysis-container > div:nth-child(3) .ui.card {
      margin: 0 !important;
      height: 85% !important;
      width: 96% !important;
      margin: 0 2% !important;
      border-radius: 18px; }
      .analysis-container > div:nth-child(3) .ui.card .image {
        position: absolute;
        top: 0;
        right: 0;
        height: 100% !important;
        width: 100% !important; }
      .analysis-container > div:nth-child(3) .ui.card .info-container {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        padding: 5%;
        z-index: 1; }
        .analysis-container > div:nth-child(3) .ui.card .info-container h2, .analysis-container > div:nth-child(3) .ui.card .info-container h3 {
          color: white;
          text-shadow: 0 3px 6px rgba(0, 0, 0, 0.31);
          margin: 0; }
      .analysis-container > div:nth-child(3) .ui.card .overlay {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        opacity: 0;
        background-color: rgba(0, 0, 0, 0.4);
        border-radius: 18px;
        z-index: 2; }
        .analysis-container > div:nth-child(3) .ui.card .overlay button {
          border-radius: 18px; }
      .analysis-container > div:nth-child(3) .ui.card .overlay:hover {
        opacity: 1;
        -webkit-backdrop-filter: blur(8px);
        backdrop-filter: blur(8px); }
    .analysis-container > div:nth-child(3) .pages-container {
      grid-area: 2 / 1 / 3 / 5;
      display: flex;
      align-items: center; }
  .analysis-container > div:last-child {
    grid-area: 3 / 1 / 4 / 2; }
  .analysis-container > .docs-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 15% 85%; }
    .analysis-container > .docs-container > div:first-child {
      grid-area: 1 / 1 / 2 / 2;
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .analysis-container > .docs-container > div:first-child button {
        background-color: #009444;
        border-radius: 0.8em;
        color: white; }
        .analysis-container > .docs-container > div:first-child button:hover {
          background-color: #009444; }
    .analysis-container > .docs-container > div:last-child {
      grid-area: 2 / 1 / 3 / 2;
      display: flex;
      justify-content: initial;
      flex-wrap: wrap;
      overflow: auto; }
      .analysis-container > .docs-container > div:last-child .doc-card {
        height: auto;
        width: 15%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 1.5% 2% 1% 2%;
        margin: 1% 1.5% !important; }
        .analysis-container > .docs-container > div:last-child .doc-card::after {
          content: none; }
        .analysis-container > .docs-container > div:last-child .doc-card div:first-child {
          width: 100%;
          margin-bottom: 12%; }
          .analysis-container > .docs-container > div:last-child .doc-card div:first-child p:nth-child(2) {
            font-size: 1.3em;
            font-weight: 600;
            margin-top: 10px;
            margin-bottom: 0; }
      .analysis-container > .docs-container > div:last-child a {
        color: white;
        background-color: #009444;
        border-radius: 0.8em; }
  .analysis-container > .placeholder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #e5e5e5;
    border-radius: 1em; }
    .analysis-container > .placeholder img {
      height: 220px;
      width: 220px; }
    .analysis-container > .placeholder p {
      font-size: 1.2em; }

.intelligence-modal {
  height: auto;
  width: 440px !important;
  padding: 1%;
  border-radius: 18px !important; }
  .intelligence-modal * {
    font-family: 'Poppins' !important; }
  .intelligence-modal div:first-child {
    width: 100%;
    text-align: center; }
    .intelligence-modal div:first-child h2 {
      font-size: 2rem;
      font-weight: 500;
      margin-bottom: 7%; }
  .intelligence-modal .predio-selected p:first-child {
    margin-bottom: 0; }
  .intelligence-modal .uploader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
    padding: 5% 0;
    margin: 4% 0;
    border-top: 1px #f4f3f3 solid;
    border-bottom: 1px #f4f3f3 solid;
    border-radius: 18px; }
    .intelligence-modal .uploader div {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      padding: 3% 1%;
      border-radius: 18px;
      background-color: #f4f3f3; }
      .intelligence-modal .uploader div input[type='file'] {
        display: none; }
      .intelligence-modal .uploader div label {
        width: 80%;
        padding: 3% 2%;
        background-color: #009444;
        font-weight: 500;
        color: white;
        border-radius: 18px; }
        .intelligence-modal .uploader div label:hover {
          cursor: pointer; }
  .intelligence-modal .date-selection {
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .intelligence-modal .date-selection .submit {
      background-color: #009444;
      font-weight: 500 !important;
      color: white;
      border-radius: 18px;
      margin: 0 !important;
      padding: 4% 0 !important; }
    .intelligence-modal .date-selection .date-picker-light-gray {
      display: block !important;
      margin-bottom: 5% !important;
      background-color: #e5e5e5;
      border-radius: 18px;
      padding: 2.5% 0;
      text-align: center; }
      .intelligence-modal .date-selection .date-picker-light-gray .react-date-picker__wrapper {
        padding: 0 5%; }
      .intelligence-modal .date-selection .date-picker-light-gray .react-date-picker__clear-button {
        display: none !important; }
      .intelligence-modal .date-selection .date-picker-light-gray .react-date-picker__button:enabled:hover .react-date-picker__button__icon,
      .intelligence-modal .date-selection .date-picker-light-gray .react-date-picker__button:enabled:focus .react-date-picker__button__icon {
        stroke: #009444 !important; }
      .intelligence-modal .date-selection .date-picker-light-gray > div > div {
        justify-content: center; }
        .intelligence-modal .date-selection .date-picker-light-gray > div > div input, .intelligence-modal .date-selection .date-picker-light-gray > div > div span {
          width: fit-content !important;
          text-align: center !important;
          color: #414042 !important; }

.report-placeholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5%;
  width: 99%;
  background-color: #E5E5E5;
  border-radius: 1em; }
  .report-placeholder h3, .report-placeholder p {
    color: #858585; }
  .report-placeholder h3 {
    font-size: 2.8em; }
  .report-placeholder p {
    font-size: 1.3em; }

.information-container {
  height: 60vh; }

.intelligence-container {
  height: 100%; }

.return-btn {
  position: absolute;
  top: 3%;
  left: 3%; }
  .return-btn button {
    border: none;
    padding-top: 0; }
  .return-btn button:hover {
    cursor: pointer; }
  .return-btn p {
    font-size: 1.1em;
    margin-left: 10px; }

/*
              _______
         ..-'`       ````---.
       .'          ___ .'````.'SS'.
      /        ..-SS####'.  /SSHH##'.
     |       .'SSSHHHH##|/#/#HH#H####'.
    /      .'SSHHHHH####/||#/: \SHH#####\
   /      /SSHHHHH#####/!||;`___|SSHH###\
-..__    /SSSHHH######.         \SSSHH###\
`.'-.''--._SHHH#####.'           '.SH####/
  '. ``'-  '/SH####`/_             `|H##/
  | '.     /SSHH###|`'==.       .=='/\H|
  |   `'-.|SHHHH##/\__\/        /\//|~|/
  |    |S#|/HHH##/             |``  |
  |    \H' |H#.'`              \    |
  |        ''`|               -     / Media Queries
  |          /H\          .----    /
  |         |H#/'.           `    /
  |          \| | '..            /
  |    ^~DLF   /|    ''..______.'
   \          //\__    _..-. | 
    \         ||   ````     \ |_
     \    _.-|               \| |_
     _\_.-'   `'''''-.        |   `--.
 ''``    \            `''-;    \ /
          \      .-'|     ````.' -
          |    .'  `--'''''-.. |/
          |  .'               \|
          |.'
*/
@media (max-height: 950px) {
  .menu-container .grid-parent div:first-child img {
    height: 240px; } }

@media (max-width: 1024px) {
  .menu-container .grid-parent div {
    justify-content: space-evenly; }
  .menu-container .grid-parent div:first-child {
    padding-right: 50%; }
    .menu-container .grid-parent div:first-child img {
      height: 255px; }
  .analysis-container > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .analysis-container > div:nth-child(2) h2 {
      font-size: 3.5em; }
    .analysis-container > div:nth-child(2) h3 {
      margin: 0; } }

@media (max-width: 768px) {
  .menu-container h3 {
    font-size: 1.5em; }
  .menu-container h2 {
    font-size: 1.6em; }
  .menu-container .grid-parent div {
    justify-content: space-evenly; }
    .menu-container .grid-parent div img {
      height: 45px; }
  .menu-container .grid-parent div:first-child {
    padding-right: 50%; }
    .menu-container .grid-parent div:first-child p {
      font-size: 0.9em; }
    .menu-container .grid-parent div:first-child img {
      height: 190px; }
  .analysis-container {
    padding-top: 10%; } }
