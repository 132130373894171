.btn-load-container {
  margin-top: -2.5rem;
  z-index: 6;
  position: relative; }
  .btn-load-container .btn-load {
    padding: 5px 20px;
    color: #009244;
    border-radius: 13px;
    background-color: white;
    box-shadow: 0 0 14px -6px rgba(0, 0, 0, 0.5); }

.list-predios a:nth-last-child(2) {
  margin-bottom: 0; }
