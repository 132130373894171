.ui.card-float-form {
  border-radius: 21.4px;
  box-shadow: 0 7px 44px -30px rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
  padding: 1.2rem; 
}

.ui.button.float-form-button {
  background-color: #009444;
  border-radius: 11px !important;
  box-shadow: 0 4px 19px -9px rgba(0, 0, 0, 0.5);
  color: #fff;
  font-family: 'Poppins';
  font-weight: 600; 
}

.ui.input.float-form-input > input {
  border: 0;
  border-radius: 11px;
  background-color: #f4f3f3;
  font-family: 'Poppins';
  font-weight: 500; 
}

.ui.input.float-form-input > input:focus {
    background-color: #f4f3f3;
    border-radius: 11px; 
}

.float-form-link{
  color: #727173;
  font-style: italic;
}

.float-form-link:hover{
  color: #414042;
}

.ui.dropdown.float-form-dropdown{
  border: 0;
  border-radius: 11px;
  background-color: #f4f3f3;
  font-family: 'Poppins';
  font-weight: 500; 
}

.ui.dropdown.float-form-dropdown > div{
  border: 0;
  border-radius: 11px;
  background-color: #f4f3f3;
  font-family: 'Poppins';
  font-weight: 500; 
}
