i.icon.navbar-icon {
  color: #009444;
  margin: 0% !important; }

.ui.vertical.menu.navbar {
  position: fixed;
  left: 0;
  width: 6% !important;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0%; }

.ui.vertical.menu.navbar > .item:first-child {
  position: absolute !important;
  top: 0 !important; }

.ui.vertical.menu.navbar > .item:last-child {
  position: absolute !important;
  bottom: 0 !important; }
