a {
  color: #009444; }

a:hover {
  color: #00c95d; }

.btn-focus-map {
  position: absolute;
  bottom: 3%;
  left: 10%;
  z-index: 2; }

.btn-share {
  position: absolute !important;
  top: 5px;
  right: 5px;
  width: auto !important;
  border-radius: 100% !important;
  background-color: white !important; }

.bitacora-img {
  height: auto;
  width: 14%; }

.bitacora-user-img {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%; }

.bitacoras-list {
  cursor: pointer;
  width: 100%; }
  .bitacoras-list:last-child {
    padding-bottom: 0.6rem !important; }

.bitacora-docs {
  cursor: pointer;
  margin: 2rem;
  height: 150px !important;
  width: 150px !important; }

.card-tag {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.25) !important; }

.date-picker {
  display: block !important;
  background-color: #f5f5f5;
  border-radius: 15px;
  padding: 10px 0;
  text-align: center; }
  .date-picker > div > div {
    justify-content: center; }
    .date-picker > div > div input, .date-picker > div > div span {
      width: fit-content !important;
      text-align: center !important;
      color: #414042 !important; }

.date-picker-light-gray {
  display: block !important;
  background-color: #e5e5e5;
  border-radius: 15px;
  padding: 10px 0;
  text-align: center; }
  .date-picker-light-gray > div > div {
    justify-content: center; }
    .date-picker-light-gray > div > div input, .date-picker-light-gray > div > div span {
      width: fit-content !important;
      text-align: center !important;
      color: #414042 !important; }

.dimmer {
  background-color: rgba(0, 0, 0, 0.6) !important; }

.dropdown-intelligence {
  display: flex !important;
  min-width: 0 !important;
  min-height: 0 !important;
  background: #f4f3f3 !important; }
  .dropdown-intelligence .ui.image {
    padding-top: .25rem !important;
    margin-left: .5rem !important;
    padding-bottom: .2rem !important; }
  .dropdown-intelligence .menu {
    border: none !important;
    border-radius: 1rem !important;
    box-shadow: 0 3px 18px 0 rgba(132, 132, 132, 0.09);
    width: auto !important; }
    .dropdown-intelligence .menu .selected {
      background-color: white !important; }
      .dropdown-intelligence .menu .selected.active span {
        font-weight: 500 !important; }
    .dropdown-intelligence .menu div[role="option"] {
      white-space: nowrap !important; }
      .dropdown-intelligence .menu div[role="option"]:hover {
        background-color: white !important; }
      .dropdown-intelligence .menu div[role="option"]:hover span {
        color: #009444 !important; }
      .dropdown-intelligence .menu div[role="option"] span {
        font-weight: 300; }

.list-cards {
  max-height: 80vh;
  overflow-y: auto;
  /* Track */ }
  .list-cards::-webkit-scrollbar {
    width: 6px; }
  .list-cards::-webkit-scrollbar-track {
    background: white; }

.modal-header {
  display: flex !important;
  justify-content: center !important; }

.navbar-profile {
  width: 5.3rem !important;
  height: 5rem !important; }

.navbar-profile-picture {
  width: 100% !important;
  height: 100% !important; }

.react-date-picker__wrapper {
  border: transparent !important; }

.scroll-historial {
  max-height: 13rem;
  overflow-y: auto; }

.scroll-newsfeed {
  max-height: 100vh;
  overflow-y: auto; }

.scroll-newsfeed-dispositivos {
  max-height: 7rem;
  overflow-y: auto; }

.ui.button.btn-noborder {
  background: transparent !important;
  border: none !important;
  box-shadow: 0 !important; }

.ui.card {
  border-radius: 1rem !important; }
  .ui.card .ui.input {
    border-radius: 1rem !important; }

.ui.input > :first-child {
  border-radius: 1rem !important; }

.user-modal-image {
  height: 200px;
  width: 200px;
  border-radius: 50%;
  margin-bottom: 1rem !important; }
