.App {
  text-align: center; }

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none; }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }

.App-link {
  color: #61dafb; }

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

body {
  font-family: Poppins;
  background-color: #f4f3f3; }

.content-container {
  height: 100vh;
  margin-top: mt3 !important; }

@font-face {
  font-family: 'Poppins';
  src: url("./app/common/fonts/Poppins/Poppins-Medium.woff2") format("woff2"), url("./app/common/fonts/Poppins/Poppins-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Poppins';
  src: url("./app/common/fonts/Poppins/Poppins-ExtraLight.woff2") format("woff2"), url("./app/common/fonts/Poppins/Poppins-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal; }

@font-face {
  font-family: 'Poppins';
  src: url("./app/common/fonts/Poppins/Poppins-Regular.woff2") format("woff2"), url("./app/common/fonts/Poppins/Poppins-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Poppins';
  src: url("./app/common/fonts/Poppins/Poppins-SemiBold.woff2") format("woff2"), url("./app/common/fonts/Poppins/Poppins-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Poppins';
  src: url("./app/common/fonts/Poppins/Poppins-LightItalic.woff2") format("woff2"), url("./app/common/fonts/Poppins/Poppins-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: 'Poppins';
  src: url("./app/common/fonts/Poppins/Poppins-ThinItalic.woff2") format("woff2"), url("./app/common/fonts/Poppins/Poppins-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic; }

@font-face {
  font-family: 'Poppins';
  src: url("./app/common/fonts/Poppins/Poppins-SemiBoldItalic.woff2") format("woff2"), url("./app/common/fonts/Poppins/Poppins-SemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic; }

@font-face {
  font-family: 'Poppins';
  src: url("./app/common/fonts/Poppins/Poppins-Thin.woff2") format("woff2"), url("./app/common/fonts/Poppins/Poppins-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: 'Poppins';
  src: url("./app/common/fonts/Poppins/Poppins-BlackItalic.woff2") format("woff2"), url("./app/common/fonts/Poppins/Poppins-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic; }

@font-face {
  font-family: 'Poppins';
  src: url("./app/common/fonts/Poppins/Poppins-MediumItalic.woff2") format("woff2"), url("./app/common/fonts/Poppins/Poppins-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-family: 'Poppins';
  src: url("./app/common/fonts/Poppins/Poppins-ExtraBold.woff2") format("woff2"), url("./app/common/fonts/Poppins/Poppins-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Poppins';
  src: url("./app/common/fonts/Poppins/Poppins-Black.woff2") format("woff2"), url("./app/common/fonts/Poppins/Poppins-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'Poppins';
  src: url("./app/common/fonts/Poppins/Poppins-ExtraLightItalic.woff2") format("woff2"), url("./app/common/fonts/Poppins/Poppins-ExtraLightItalic.woff") format("woff");
  font-weight: 200;
  font-style: italic; }

@font-face {
  font-family: 'Poppins';
  src: url("./app/common/fonts/Poppins/Poppins-BoldItalic.woff2") format("woff2"), url("./app/common/fonts/Poppins/Poppins-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic; }

@font-face {
  font-family: 'Poppins';
  src: url("./app/common/fonts/Poppins/Poppins-Italic.woff2") format("woff2"), url("./app/common/fonts/Poppins/Poppins-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'Poppins';
  src: url("./app/common/fonts/Poppins/Poppins-Light.woff2") format("woff2"), url("./app/common/fonts/Poppins/Poppins-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Poppins';
  src: url("./app/common/fonts/Poppins/Poppins-ExtraBoldItalic.woff2") format("woff2"), url("./app/common/fonts/Poppins/Poppins-ExtraBoldItalic.woff") format("woff");
  font-weight: 800;
  font-style: italic; }

@font-face {
  font-family: 'Poppins';
  src: url("./app/common/fonts/Poppins/Poppins-Bold.woff2") format("woff2"), url("./app/common/fonts/Poppins/Poppins-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal; }
