.error-container {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #e5e5e5; }
  .error-container div {
    position: absolute;
    top: 3%;
    left: 3%; }
    .error-container div button {
      border: none;
      padding-top: 0; }
    .error-container div button:hover {
      cursor: pointer; }
    .error-container div p {
      font-size: 1.1em;
      margin-left: 10px; }
  .error-container h2, .error-container p {
    color: #858585; }
  .error-container h2 {
    font-size: 3em; }
  .error-container p {
    font-size: 1.5em; }
