.br2-5 {
  border-radius: 2.5rem !important; }

.br18px {
  border-radius: 1.125rem !important; }

.br17px {
  border-radius: 17px; }

.bsn {
  box-shadow: none !important; }

.center {
  margin-left: auto !important;
  margin-right: auto !important; }

.dib {
  display: inline block !important; }

.f1 {
  font-size: 3rem !important; }

.h-25px {
  height: 25px !important; }

.h-98 {
  height: 98% !important; }

.h-100 {
  height: 100% !important; }

.h-120px {
  height: 120px; }

.left-1 {
  left: 1rem !important; }

.lh-36 {
  line-height: 36px; }

.ma2-5 {
  margin: .5rem !important; }

.ma3 {
  margin: 1rem !important; }

.mb0 {
  margin-bottom: 0 !important; }

.mb3 {
  margin-bottom: 1rem !important; }

.mb4 {
  margin-bottom: 2rem !important; }

.ml95pct {
  margin-left: 95% !important; }

.mln-5 {
  margin-left: -0.5rem !important; }

.mln1 {
  margin-left: -1rem !important; }

.ml25pct {
  margin-left: 25% !important; }

.ml1-4 {
  margin-left: 1.4em; }

.ml3 {
  margin-left: 1rem !important; }

.ml4 {
  margin-left: 2rem !important; }

.ml4-5 {
  margin-left: 3rem !important; }

.ml--30px {
  margin-left: -30px !important; }

.mr25pct {
  margin-right: 25% !important; }

.mr2 {
  margin-right: .5rem !important; }

.mt0 {
  margin-top: 0 !important; }

.mt0-5 {
  margin-top: 0.55rem !important; }

.mt0-75 {
  margin-top: 0.75rem !important; }

.mt0-8 {
  margin-top: .9rem !important; }

.mt2 {
  margin-top: .5rem !important; }

.mt2px {
  margin-top: 2px !important; }

.mt3 {
  margin-top: 1rem !important; }

.mta {
  margin-top: auto !important; }

.pa0 {
  padding: 0 !important; }

.pa1 {
  padding: .25rem !important; }

.pa2-5 {
  padding: 0.75rem !important; }

.pa2 {
  padding: 0.5rem !important; }

.pa4 {
  padding: 1rem !important; }

.pb0 {
  padding-bottom: 0 !important; }

.pointer {
  cursor: pointer !important; }

.pr0-5 {
  padding-right: 0.5rem !important; }

.preh {
  overflow-x: auto;
  overflow-y: hidden; }
  .preh::-webkit-scrollbar {
    width: auto;
    height: .3rem; }

.pt0 {
  padding-top: 0 !important; }

.pt3 {
  padding-top: 1rem !important; }

.pr2 {
  padding-right: .5rem !important; }

.pr4-5 {
  padding-right: 2.5rem !important; }

.top--5px {
  top: -5px !important; }

.ui.card:first-child.br18 {
  border-radius: 18px !important; }

.ui.card .br18 {
  border-radius: 18px !important; }

.w-6 {
  width: 6% !important; }

.w-16 {
  width: 16% !important; }

.w-70 {
  width: 70% !important; }

.w-75 {
  width: 75% !important; }

.w-80 {
  width: 80% !important; }

.w-140 {
  width: 140% !important; }

.flex-center {
  display: flex !important;
  justify-content: center !important; }

.m0a {
  margin: 0 auto; }

.ov-h {
  overflow: hidden !important; }
