/* COLORS */
.charcoal-grey {
  color: #414042 !important; }

.lighter-gray {
  color: #f4f3f3 !important; }

.light-gray {
  color: #adadad !important; }

.teal {
  color: #40dfc8 !important; }

/* BACKGROUND COLORS */
.bg-gradient {
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.6), transparent); }

.bg-green {
  background-color: #009444 !important; }
  .bg-green.button {
    color: #f4f3f3 !important; }

.bg-gray {
  background-color: #adadad !important; }

.bg-light-gray {
  background-color: #e5e5e5 !important; }

.bg-lighter-gray {
  background-color: #f4f3f3 !important; }

.bg-red {
  background-color: #fee7e7 !important; }

.bg-white {
  background-color: white !important; }
